import RingTheme, { applyTheme } from '@jetbrains/ring-ui-built/components/global/theme.js';
import { Theme } from '../components/Theme/ThemeContext.js';

const isDark = theme => {
  return theme === Theme.dark;
};
const isAuto = theme => {
  return theme === Theme.auto;
};
const getCurrentThemeColor = (theme, systemTheme) => {
  if (isAuto(theme) && systemTheme) {
    return systemTheme;
  } else {
    return theme;
  }
};
const isMatchMediaAvailable = () => {
  return typeof window !== 'undefined' && !!window.matchMedia;
};
const updateMetaThemeColor = (currentTheme, color, metaRef) => {
  if (isAuto(currentTheme)) {
    if (metaRef.current && document.head.contains(metaRef.current)) {
      document.head.removeChild(metaRef.current);
      metaRef.current = null;
    }
  } else {
    if (metaRef.current) {
      metaRef.current?.setAttribute('content', color);
    } else {
      const predefinedMetaThemeColors = document.querySelectorAll('meta[name="theme-color"]');
      const meta = document.createElement('meta');
      meta.name = 'theme-color';
      meta.content = color;
      metaRef.current = meta;
      if (predefinedMetaThemeColors.length === 2) {
        predefinedMetaThemeColors[0].before(meta);
      }
    }
  }
};
const updateBodyClass = currentTheme => {
  const {
    body
  } = document;
  body.classList.add('bodyQodanaBlocks');
  if (isDark(currentTheme)) {
    body.classList.add('bodyQodanaBlocks_dark');
    applyTheme(RingTheme.DARK, body);
  } else {
    body.classList.remove('bodyQodanaBlocks_dark');
    applyTheme(RingTheme.LIGHT, body);
  }
};

export { getCurrentThemeColor, isAuto, isDark, isMatchMediaAvailable, updateBodyClass, updateMetaThemeColor };
