import { StepPosition } from '../constants.js';

const getStepPosition = (currentStepIndex, index) => {
  if (index === currentStepIndex) {
    return StepPosition.current;
  } else if (index < currentStepIndex) {
    return StepPosition.previous;
  } else {
    return StepPosition.future;
  }
};

export { getStepPosition };
