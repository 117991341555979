import ringAlert from '@jetbrains/ring-ui-built/components/alert-service/alert-service.js';
import styles from './alert.module.css.js';

var AlertType;
(function (AlertType) {
  AlertType["ERROR"] = "error";
  AlertType["MESSAGE"] = "message";
  AlertType["SUCCESS"] = "success";
  AlertType["WARNING"] = "warning";
  AlertType["LOADING"] = "loading";
})(AlertType || (AlertType = {}));
const DEFAULT_TIMEOUT = undefined;
const alert = function (message, type, options) {
  let timeout = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : DEFAULT_TIMEOUT;
  return ringAlert.addAlert(message, type, timeout, options);
};
const showError = (message, timeout) => {
  return alert(message, AlertType.ERROR, {
    className: styles.error
  }, timeout);
};
const showMessage = (message, timeout) => {
  return alert(message, AlertType.MESSAGE, {
    className: styles.message
  }, timeout);
};
const showWarning = (message, timeout) => {
  return alert(message, AlertType.WARNING, {
    className: styles.warning
  }, timeout);
};
const showSuccess = (message, timeout) => {
  return alert(message, AlertType.SUCCESS, {
    className: styles.success
  }, timeout);
};
const showLoading = (message, timeout) => {
  return alert(message, AlertType.LOADING, {
    className: styles.loading
  }, timeout);
};
const removeAlert = key => {
  ringAlert.remove(key);
};
const removeAllAlerts = () => {
  ringAlert.showingAlerts = [];
  ringAlert.renderAlerts();
};

export { removeAlert, removeAllAlerts, showError, showLoading, showMessage, showSuccess, showWarning };
