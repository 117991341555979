import { jsx } from 'react/jsx-runtime.js';
import cn from 'classnames';
import { H3 } from '@jetbrains/ring-ui-built/components/heading/heading.js';
import { TestAnchors } from '../test.js';
import styles from './StepTitle.module.css.js';

const StepTitle = props => {
  const {
    isCurrentStep,
    style = {},
    children
  } = props;
  return jsx(H3, {
    className: cn(styles.title, {
      [styles.currentStep]: isCurrentStep
    }),
    style: style,
    "data-qd-test": TestAnchors.stepLabel,
    children: children
  });
};

export { StepTitle };
