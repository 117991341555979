import { useState, useEffect } from 'react';
import { isMatchMediaAvailable } from '../utils/theme.js';

const useMatchMedia = mediaQuery => {
  const [isMatchQuery, setIsMatchQuery] = useState(() => checkMediaQuery(mediaQuery));
  useEffect(() => {
    if (!isMatchMediaAvailable()) {
      return;
    }
    const match = window.matchMedia(mediaQuery);
    const callback = e => {
      setIsMatchQuery(e.matches);
    };
    // for old Safari
    match.addEventListener ? match.addEventListener('change', callback) : match.addListener(callback);
    return () => {
      // for old Safari
      match.removeEventListener ? match.removeEventListener('change', callback) : match.removeListener(callback);
    };
  }, [mediaQuery]);
  return isMatchQuery;
};
const checkMediaQuery = mediaQuery => {
  if (!isMatchMediaAvailable()) {
    return;
  }
  const match = window.matchMedia(mediaQuery);
  return match.matches;
};

export { useMatchMedia };
