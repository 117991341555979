import { jsxs, jsx } from 'react/jsx-runtime.js';
import { useCallback } from 'react';
import { Button } from '@jetbrains/ring-ui-built/components/button/button.js';
import { TestAnchors } from '../../test.js';
import styles from './ChangeStepButtons.module.css.js';

const ChangeStepButtons = props => {
  const {
    index,
    disabled,
    onBack,
    onNext,
    steps
  } = props;
  const {
    skipAll,
    skipStep,
    onComplete,
    dataQdTest,
    dataQdClickable,
    completeButtonLabel
  } = steps[index];
  const onNextClick = useCallback(() => onNext(), [onNext]);
  const onSkipStepClick = useCallback(() => onNext(true), [onNext]);
  return jsxs("div", {
    className: styles.buttons,
    children: [index > 0 && onBack && jsx(Button, {
      loader: disabled,
      disabled: disabled,
      "data-qd-clickable": dataQdClickable ? `${dataQdClickable}-back-btn` : undefined,
      "data-qd-test": dataQdTest?.back || TestAnchors.backButton,
      onClick: onBack,
      children: "Back"
    }), onComplete && index === steps.length - 1 ? jsx(Button, {
      loader: disabled,
      disabled: disabled,
      "data-qd-clickable": dataQdClickable ? `${dataQdClickable}-complete-btn` : undefined,
      "data-qd-test": dataQdTest?.complete || TestAnchors.completeButton,
      primary: true,
      onClick: onComplete,
      children: completeButtonLabel || 'Complete setup'
    }) : index < steps.length - 1 ? jsx(Button, {
      loader: disabled,
      disabled: disabled,
      "data-qd-clickable": dataQdClickable ? `${dataQdClickable}-next-btn` : undefined,
      "data-qd-test": dataQdTest?.next || TestAnchors.nextButton,
      primary: true,
      onClick: onNextClick,
      children: "Next"
    }) : null, skipStep && jsx(Button, {
      text: true,
      loader: disabled,
      disabled: disabled,
      "data-qd-clickable": dataQdClickable ? `${dataQdClickable}-skip-step` : undefined,
      "data-qd-test": TestAnchors.skipStepButton,
      onClick: onSkipStepClick,
      className: styles.skipButton,
      children: typeof skipStep === 'string' ? skipStep : 'Skip this step'
    }), skipAll && onComplete && jsx(Button, {
      text: true,
      loader: disabled,
      disabled: disabled,
      "data-qd-clickable": dataQdClickable ? `${dataQdClickable}-skip-all` : undefined,
      "data-qd-test": TestAnchors.skipButton,
      onClick: onComplete,
      className: styles.skipButton,
      children: "Stop here and continue later"
    })]
  });
};

export { ChangeStepButtons };
