import { useContext, useState, useEffect } from 'react';
import { ApplicationMenuEvents } from '../../utils/applicationMenu/applicationMenu.js';
import '@babel/runtime/helpers/defineProperty';
import { ApplicationMenuContext } from './ApplicationMenuContext.js';

function useSubscribeMenuItems(menu) {
  const contextMenu = useContext(ApplicationMenuContext);
  const subscribedMenu = menu || contextMenu;
  const [items, setItems] = useState(() => subscribedMenu?.items || []);
  useEffect(() => {
    if (!subscribedMenu) {
      return undefined;
    }
    const handleMenuItemsChange = () => {
      setItems(subscribedMenu.items);
    };
    subscribedMenu.addEventListener(ApplicationMenuEvents.ItemsUpdated, handleMenuItemsChange);
    setItems(subscribedMenu.items);
    return () => {
      subscribedMenu.removeEventListener(ApplicationMenuEvents.ItemsUpdated, handleMenuItemsChange);
    };
  }, [subscribedMenu]);
  return items;
}

export { useSubscribeMenuItems };
