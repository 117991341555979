import { jsxs, jsx } from 'react/jsx-runtime.js';
import Close from '@jetbrains/icons/close.js';
import { ControlsHeight } from '@jetbrains/ring-ui-built/components/global/controls-height.js';
import { Size } from '@jetbrains/ring-ui-built/components/icon/icon.js';
import { Button } from '@jetbrains/ring-ui-built/components/button/button.js';
import cn from 'classnames';
import { Fragment } from 'react';
import typographyStyles from '../Typography/Typography.module.css.js';
import styles from './Banner.module.css.js';

function Banner(props) {
  const {
    children,
    type,
    action,
    className,
    onClose,
    'data-qd-test': dataQdTest
  } = props;
  const actionTemplate = () => {
    if (!action) {
      return undefined;
    }
    if ('custom' in action) {
      return jsx(Fragment, {
        children: jsx("span", {
          children: action.custom
        })
      }, action.key);
    }
    const {
      children,
      key,
      ...buttonProps
    } = action;
    return jsx(Button, {
      "data-qd-test": key,
      primary: true,
      height: ControlsHeight.S,
      className: cn(className, styles.actionButton),
      ...buttonProps,
      children: children
    }, key);
  };
  return jsxs("div", {
    className: cn(styles.banner, styles[type], className),
    "data-qd-test": dataQdTest,
    children: [jsx("div", {
      className: cn(styles.text, typographyStyles.smallText),
      children: children
    }), jsxs("div", {
      className: cn(styles.btnContainer, {
        [styles.withAction]: !!action
      }),
      children: [actionTemplate(), onClose && jsx(Button, {
        "aria-label": "Close",
        className: styles.closeButton,
        iconClassName: cn(styles.closeIcon, styles[`${type}CloseButton`]),
        onClick: onClose,
        iconSize: Size.Size12,
        icon: Close
      })]
    })]
  });
}

export { Banner };
