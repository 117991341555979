import { compressToEncodedURIComponent, decompressFromEncodedURIComponent } from 'lz-string';

let topLocationErrorShown = false;
/**
 * Returns the current `location` and `history` objects from `window.top` if it exists and not blocked by CORS or `window.location` and `window.history` otherwise.
 */
function getCurrentGlobals() {
  if (window.top) {
    try {
      return {
        location: window.top.location,
        history: window.top.history
      };
    } catch {
      if (!topLocationErrorShown) {
        console.debug(`Error getting top location. Using window.location`);
        topLocationErrorShown = true;
      }
    }
  }
  return {
    location: window.location,
    history: window.history
  };
}
const addParameterToUrl = function (key, value) {
  let encoded = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  const {
    location,
    history
  } = getCurrentGlobals();
  const url = new URL(location.href);
  const param = encoded ? compressToEncodedURIComponent(value) : value;
  url.searchParams.set(key, param);
  history.replaceState(null, '', url.href);
};
const getParameterFromUrl = function (key) {
  let parseAsJSON = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  let encoded = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  let falsyValue = arguments.length > 3 ? arguments[3] : undefined;
  const {
    location
  } = getCurrentGlobals();
  let value;
  try {
    const url = new URL(location.href);
    value = url.searchParams.get(key) || '';
  } catch (e) {
    console.warn(`Error getting URL parameter: '${e.message}'. No data retrieved`);
    return null;
  }
  // TODO improve or delete
  // try {
  // 	if (!value || value === falsyValue) {
  // 		return null
  // 	}
  // 	return parseAsJSON ? JSON.parse(value) : value
  // } catch (e) {
  // 	console.log(`couldn't parse URL parameter as is: ${e.message}, it's decompression time`)
  // }
  const param = encoded ? decompressFromEncodedURIComponent(value) : value;
  try {
    if (!param || param === falsyValue) {
      return null;
    }
    return parseAsJSON ? JSON.parse(param) : param;
  } catch (e) {
    console.warn(`Error parsing URL parameter: '${e.message}'. No data retrieved`);
    return null;
  }
};
const removeParamsFromUrl = keys => {
  const {
    location,
    history
  } = getCurrentGlobals();
  const url = new URL(location.href);
  const params = new URLSearchParams(url.search);
  const urlNoParams = url.toString().split('?')[0];
  for (const key of keys) {
    params.delete(key);
  }
  const resultParams = params.toString() ? `?${params.toString()}` : '';
  history.replaceState(null, '', `${urlNoParams}${resultParams}`);
};

export { addParameterToUrl, getParameterFromUrl, removeParamsFromUrl };
