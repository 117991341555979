import { jsxs, jsx } from 'react/jsx-runtime.js';
import cn from 'classnames';
import { useRef } from 'react';
import { StepPosition } from '../constants.js';
import { StepTitle } from '../StepTitle/StepTitle.js';
import { TestAnchors } from '../test.js';
import styles from './VerticalContent.module.css.js';

// FIXME(QD-6235): Restore animation, uncomment code
const VerticalContent = props => {
  const {
    label,
    size: {
      lineSize,
      circleSize
    },
    children,
    position
  } = props;
  const ref = useRef(null);
  // const [height, setHeight] = useState(0)
  const isCurrentStep = position === StepPosition.current;
  const style = {
    label: {
      lineHeight: `${circleSize}px`
    },
    content: {
      /*height, */marginTop: lineSize
    }
  };
  // useEffect(() => {
  // 	if (!isCurrentStep || !ref?.current) {
  // 		setHeight(0)
  // 	} else {
  // 		setHeight(ref.current.scrollHeight)
  // 	}
  // }, [isCurrentStep])
  return jsxs("div", {
    className: styles.container,
    children: [jsx(StepTitle, {
      isCurrentStep: position === StepPosition.current,
      style: style.label,
      children: label
    }), jsx("div", {
      ref: ref,
      className: cn(styles.content, {
        [styles.openedContent]: isCurrentStep
      }),
      style: style.content,
      hidden: !isCurrentStep,
      "data-qd-test": TestAnchors.verticalContent,
      children: isCurrentStep && children
    })]
  });
};

export { VerticalContent };
