import { jsxs, jsx } from 'react/jsx-runtime.js';
import cn from 'classnames';
import CheckmarkIcon from '@jetbrains/icons/checkmark.js';
import Icon from '@jetbrains/ring-ui-built/components/icon/icon.js';
import { Text } from '../../Typography/Typography.js';
import { StepPosition } from '../constants.js';
import styles from './Step.module.css.js';

const Step = props => {
  const {
    size: {
      lineSize,
      circleSize,
      iconSize
    },
    stepNumber,
    isLast,
    position,
    vertical
  } = props;
  const lineStyle = vertical ? 'height' : 'width';
  const style = {
    line: {
      [lineStyle]: lineSize
    },
    completedLine: {
      [lineStyle]: position === StepPosition.previous ? '100%' : 0
    },
    circle: {
      width: circleSize,
      height: circleSize
    }
  };
  return jsxs("div", {
    className: cn(styles.step, styles[position], {
      [styles.lastStep]: isLast,
      [styles.horizontal]: !vertical
    }),
    children: [jsxs("div", {
      className: styles.circle,
      style: style.circle,
      children: [stepNumber && jsx(Text, {
        className: styles.number,
        children: stepNumber
      }), jsx(Icon, {
        glyph: CheckmarkIcon,
        size: iconSize,
        className: styles.checkmarkIcon,
        suppressSizeWarning: true
      })]
    }), !isLast && jsx("div", {
      className: styles.line,
      style: style.line,
      children: jsx("div", {
        className: styles.completedLine,
        style: style.completedLine
      })
    })]
  });
};

export { Step };
