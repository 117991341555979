import _defineProperty from '@babel/runtime/helpers/defineProperty';

class EventTargetFallback {
  constructor() {
    // EventTarget methods
    _defineProperty(this, "addEventListener", void 0);
    _defineProperty(this, "removeEventListener", void 0);
    _defineProperty(this, "dispatchEvent", void 0);
    // Create an instance of the native EventTarget
    const eventTarget = document.createDocumentFragment();
    this.addEventListener = eventTarget.addEventListener.bind(eventTarget);
    this.removeEventListener = eventTarget.removeEventListener.bind(eventTarget);
    this.dispatchEvent = eventTarget.dispatchEvent.bind(eventTarget);
  }
}

export { EventTargetFallback };
