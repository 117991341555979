import { jsxs, Fragment, jsx } from 'react/jsx-runtime.js';
import cn from 'classnames';
import styles from './TextArea.module.css.js';

const TextArea = props => {
  const {
    className,
    invalid,
    resizable,
    error,
    ...restProps
  } = props;
  return jsxs(Fragment, {
    children: [jsx("textarea", {
      className: cn(styles.qdTextarea, className, {
        [styles.invalid]: invalid,
        [styles.resizable]: resizable
      }),
      id: props.id || '',
      name: props.controlName,
      ...restProps
    }), jsx("div", {
      className: styles.error,
      children: error || null
    })]
  });
};

export { TextArea };
