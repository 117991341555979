const getAttributeFromTaggedParent = (el, parentTagName, attr) => {
  let parent = el.parentElement;
  while (parent && parent.tagName !== parentTagName) {
    parent = parent.parentElement;
  }
  return parent ? parent.getAttribute(attr) || '' : '';
};
const findClosestAttribute = (el, attr) => {
  let currentEl = el;
  while (currentEl) {
    const value = currentEl.getAttribute(attr) || '';
    if (value) {
      return value;
    }
    currentEl = currentEl.parentElement;
  }
  return null;
};

export { findClosestAttribute, getAttributeFromTaggedParent };
