import _defineProperty from '@babel/runtime/helpers/defineProperty';
import 'core-js/modules/es.array.push.js';
import 'core-js/modules/esnext.iterator.constructor.js';
import 'core-js/modules/esnext.iterator.for-each.js';
import 'core-js/modules/esnext.iterator.map.js';
import { EventTargetFallback } from './eventTargetFallback.js';
import { SubmenuMenuItem } from './itemTypes.js';

var _ApplicationMenu;
var ApplicationMenuEvents;
(function (ApplicationMenuEvents) {
  ApplicationMenuEvents["ItemsUpdated"] = "items-updated";
})(ApplicationMenuEvents || (ApplicationMenuEvents = {}));
let SupportedEventTarget = EventTarget;
try {
  new EventTarget();
} catch {
  // fallback
  SupportedEventTarget = EventTargetFallback;
}
class ApplicationMenu extends SupportedEventTarget {
  constructor() {
    super(...arguments);
    _defineProperty(this, "_items", []);
  }
  static buildFromTemplate(template) {
    const menu = new ApplicationMenu();
    const items = template.map(i => 'submenu' in i ? new SubmenuMenuItem(i) : i);
    menu.items = orderItems(items);
    return menu;
  }
  get items() {
    return this._items;
  }
  set items(value) {
    this._items = value;
    this.dispatchEvent(new Event(ApplicationMenuEvents.ItemsUpdated));
  }
  append(menuItem) {
    const {
      before,
      after
    } = menuItem;
    let referenceIndex = -1;
    if (before) {
      referenceIndex = findIndexByIdOrLabel(before, this._items);
      if (referenceIndex !== -1) {
        this._items.splice(referenceIndex, 0, menuItem);
      }
    }
    if (after && referenceIndex === -1) {
      referenceIndex = findIndexByIdOrLabel(after, this._items);
      if (referenceIndex !== -1) {
        this._items.splice(referenceIndex + 1, 0, menuItem);
      }
    }
    if (referenceIndex === -1) {
      this._items.push(menuItem);
    }
    // Sorting elements referring to the added
    this.items = orderItems(this._items, menuItem);
  }
  getMenuItemById(id) {
    for (let item of this.items) {
      if (item.id === id) {
        return item;
      }
      if (item.type === 'submenu') {
        const submenuItem = item.submenu.getMenuItemById(id);
        if (submenuItem) {
          return submenuItem;
        }
      }
    }
    return null;
  }
  getSubmenuMenuItemById(id) {
    for (let item of this.items) {
      if (item.id === id && item.type === 'submenu') {
        return item;
      }
    }
    return null;
  }
}
_ApplicationMenu = ApplicationMenu;
_defineProperty(ApplicationMenu, "menu", new _ApplicationMenu());
_defineProperty(ApplicationMenu, "getApplicationMenu", () => {
  return _ApplicationMenu.menu;
});
_defineProperty(ApplicationMenu, "setApplicationMenu", value => {
  _ApplicationMenu.menu = value;
});
function findIndexByIdOrLabel(pattern, items) {
  return items.findIndex(x => {
    if (x.id && pattern.includes(x.id)) {
      return true;
    }
    return pattern.includes(x.label);
  });
}
function findReferenceIndex(references, arr, existsReferences) {
  for (let j = 0; j < references.length; ++j) {
    // Skipping references which absence in `arr`
    if (!existsReferences.has(references[j])) {
      continue;
    }
    return arr.findIndex(x => x.id === references[j] || x.label === references[j]);
  }
  return -1;
}
function orderItems(arr, afterAdding) {
  const orderedList = [...arr];
  const references = new Set();
  if (afterAdding) {
    if (afterAdding.id) {
      references.add(afterAdding.id);
    }
    references.add(afterAdding.label);
  } else {
    arr.forEach(x => {
      if (x.id) {
        references.add(x.id);
      }
      references.add(x.label);
    });
  }
  for (let i = 0; i < arr.length; ++i) {
    const {
      after,
      before
    } = arr[i];
    if (before) {
      let referenceIndex = findReferenceIndex(before, arr, references);
      if (referenceIndex !== -1) {
        delete orderedList[i];
        orderedList[referenceIndex] = [arr[i], orderedList[referenceIndex]];
        continue;
      }
    }
    if (after) {
      let referenceIndex = findReferenceIndex(after, arr, references);
      if (referenceIndex !== -1) {
        delete orderedList[i];
        orderedList[referenceIndex] = [orderedList[referenceIndex], arr[i]];
        // noinspection UnnecessaryContinueJS
        continue;
      }
    }
  }
  return orderedList.flat();
}

export { ApplicationMenu, ApplicationMenuEvents, orderItems };
