import { equalPrimitiveArrays } from './array.js';

const equalFlatObjects = (a, b) => {
  if (a === b) {
    return true;
  }
  if (Object.keys(a).length !== Object.keys(b).length) {
    return false;
  }
  for (const key of Object.keys(a)) {
    const aVal = a[key];
    const bVal = b[key];
    if (isPrimitiveArray(aVal) && isPrimitiveArray(bVal)) {
      if (!equalPrimitiveArrays(aVal, bVal)) {
        return false;
      }
    } else {
      if (aVal !== bVal) {
        return false;
      }
    }
  }
  return true;
};
const removeKeysFromObj = (obj, targetKeys) => {
  const newObj = {};
  for (const unit of Object.keys(obj)) {
    if (!targetKeys.includes(unit)) {
      newObj[unit] = obj[unit];
    }
  }
  return newObj;
};
const hasOwnProp = (obj, prop) => Object.prototype.hasOwnProperty.call(obj, prop);
function isPrimitiveArray(value) {
  return Array.isArray(value);
}

export { equalFlatObjects, hasOwnProp, removeKeysFromObj };
