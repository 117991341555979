import { jsx } from 'react/jsx-runtime.js';
import cn from 'classnames';
import { useState } from 'react';
import { SubmenuPopup } from '../ApplicationMenu/SubmenuPopup/SubmenuPopup.js';
import typographyStyles from '../Typography/Typography.module.css.js';

function HeaderSubmenu(_ref) {
  let {
    className,
    submenuClassName,
    item
  } = _ref;
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const isDisabled = item.enabled === false;
  return jsx(SubmenuPopup, {
    className: submenuClassName,
    open: open,
    menu: item.submenu,
    onClose: handleClose,
    children: jsx("button", {
      className: cn(typographyStyles.text, className),
      onClick: handleOpen,
      disabled: isDisabled,
      children: item.label
    }, item.id || item.label)
  });
}

export { HeaderSubmenu };
