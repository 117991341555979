import { jsxs, Fragment, jsx } from 'react/jsx-runtime.js';
import { ChangeStepButtons } from './ChangeStepButtons/ChangeStepButtons.js';

const StepContent = props => {
  const {
    steps,
    index,
    disabled,
    onCurrentIndexChange,
    onNext,
    onCurrentHandlerClear,
    ...rest
  } = props;
  const currentStep = steps[index];
  const CurrentStepComponent = currentStep.content;
  const handleBackClick = currentStep.isBackButtonVisible !== false ? () => {
    onCurrentHandlerClear();
    onCurrentIndexChange(prev => prev - 1);
  } : undefined;
  return jsxs(Fragment, {
    children: [jsx(CurrentStepComponent, {
      ...rest
    }), jsx(ChangeStepButtons, {
      onNext: onNext,
      onBack: handleBackClick,
      steps: steps,
      index: index,
      disabled: disabled
    })]
  });
};

export { StepContent };
