import { useContext, useState, useEffect } from 'react';
import { ApplicationMenuEvents } from '../../utils/applicationMenu/applicationMenu.js';
import { isSubmenuMenuItem } from '../../utils/applicationMenu/itemTypes.js';
import { ApplicationMenuContext } from './ApplicationMenuContext.js';

const useSubscribeSubMenuItems = submenuId => {
  const menu = useContext(ApplicationMenuContext);
  const subscribedMenu = menu?.getMenuItemById(submenuId);
  const [items, setItems] = useState(() => subscribedMenu && isSubmenuMenuItem(subscribedMenu) ? subscribedMenu.submenu.items : []);
  useEffect(() => {
    if (!subscribedMenu) {
      return;
    } else if (!isSubmenuMenuItem(subscribedMenu)) {
      console.error(`Element has the wrong type: ${submenuId}`);
      return;
    }
    const handleMenuItemsChange = () => {
      setItems(subscribedMenu.submenu.items);
    };
    subscribedMenu.submenu.addEventListener(ApplicationMenuEvents.ItemsUpdated, handleMenuItemsChange);
    setItems(subscribedMenu.submenu.items);
    return () => {
      subscribedMenu.submenu.removeEventListener(ApplicationMenuEvents.ItemsUpdated, handleMenuItemsChange);
    };
  }, [subscribedMenu, submenuId]);
  return items;
};

export { useSubscribeSubMenuItems };
