const isDomainInWhitelist = (url, whitelist) => {
  try {
    const urlObject = new URL(url);
    const hostname = urlObject.hostname;
    return whitelist.includes(hostname);
  } catch (error) {
    console.error(error);
    return false;
  }
};

export { isDomainInWhitelist };
