import { jsxs, jsx } from 'react/jsx-runtime.js';
import { Button } from '@jetbrains/ring-ui-built/components/button/button.js';
import Close from '@jetbrains/icons/close.js';
import cn from 'classnames';
import { useCallback } from 'react';
import SearchIcon from '@jetbrains/icons/search.js';
import Icon, { Size } from '@jetbrains/ring-ui-built/components/icon/icon.js';
import styles from './SearchBoxInput.module.css.js';

const SearchBoxInput = props => {
  const {
    className = '',
    disabled = false,
    htmlProps,
    onBlur,
    onChange,
    onFocus,
    placeholder = 'Search',
    showingIcon = true,
    value,
    ...restProps
  } = props;
  const handleChange = useCallback(e => onChange(e.currentTarget.value), [onChange]);
  const handleReset = useCallback(e => {
    e.nativeEvent.stopImmediatePropagation();
    onChange('');
  }, [onChange]);
  return jsxs("div", {
    className: cn(styles.container, className, {
      [styles.disabled]: disabled
    }),
    ...htmlProps,
    children: [jsx("input", {
      autoComplete: 'off',
      disabled: disabled,
      onBlur: onBlur,
      onChange: handleChange,
      onFocus: onFocus,
      placeholder: placeholder,
      value: value,
      ...restProps
    }), value === '' ? showingIcon ? jsx(Icon, {
      glyph: SearchIcon,
      className: cn(styles.icon, 'scalableIcon')
    }) : null : jsx(Button, {
      className: styles.resetButton,
      icon: Close,
      iconClassName: cn(styles.resetIcon, 'scalableIcon'),
      iconSize: Size.Size12,
      iconSuppressSizeWarning: true,
      onClick: handleReset
    })]
  });
};

export { SearchBoxInput };
