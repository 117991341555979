import 'core-js/modules/esnext.iterator.map.js';
import { jsxs, jsx } from 'react/jsx-runtime.js';
import QodanaLogo from '../../node_modules/@jetbrains/logos/qodana/qodana.js';
import cn from 'classnames';
import { useContext } from 'react';
import { DEFAULT_ENLARGEMENT_COEFFICIENT } from '../../config/globalConstants.js';
import 'uuid';
import { useMatchMedia } from '../../hooks/useMatchMedia.js';
import { isDark, getCurrentThemeColor } from '../../utils/theme.js';
import { ThemeContext } from '../Theme/ThemeContext.js';
import typographyStyles from '../Typography/Typography.module.css.js';
import styles from './Header.module.css.js';
import { HeaderSubmenu } from './HeaderSubmenu.js';
import { ThemeToggle } from './ThemeToggle/ThemeToggle.js';

function Header(props) {
  const {
    className,
    themeToggleHtmlProps,
    userControls,
    menuItems = []
  } = props;
  const {
    theme,
    systemTheme,
    onThemeChange
  } = useContext(ThemeContext);
  const isDarkTheme = isDark(getCurrentThemeColor(theme, systemTheme));
  const isLargeScreen = useMatchMedia('(min-width: 2540px)');
  const enlargementCoefficient = isLargeScreen ? DEFAULT_ENLARGEMENT_COEFFICIENT : 1;
  const qodanaLogoSize = 48 * enlargementCoefficient;
  return jsxs("header", {
    className: cn(styles.header, className),
    children: [jsx(QodanaLogo, {
      className: styles.logo,
      height: qodanaLogoSize,
      width: qodanaLogoSize,
      "aria-label": "Qodana product logo"
    }), menuItems.length > 0 && jsx("div", {
      className: styles.menuItems,
      children: menuItems.map(item => item.type !== 'submenu' ? jsx("button", {
        className: cn(typographyStyles.text, styles.menuItem, styles.rootMenuItem),
        onClick: item.click,
        disabled: item.enabled === false,
        children: item.label
      }, item.id || item.label) : jsx(HeaderSubmenu, {
        submenuClassName: styles.rootMenuItem,
        className: styles.menuItem,
        item: item
      }, item.id || item.label))
    }), jsxs("div", {
      className: styles.controls,
      children: [userControls, !!onThemeChange && jsx(ThemeToggle, {
        defaultChecked: isDarkTheme,
        htmlProps: themeToggleHtmlProps,
        onChange: onThemeChange
      })]
    })]
  });
}

export { Header };
