import { jsx } from 'react/jsx-runtime.js';
import Link from '@jetbrains/ring-ui-built/components/link/link.js';

const EXTERNAL_LINK_PARAMETERS = {
  target: '_blank',
  rel: 'noopener noreferrer'
};
const ExternalLink = props => {
  return jsx(Link, {
    ...props,
    ...EXTERNAL_LINK_PARAMETERS
  });
};

export { EXTERNAL_LINK_PARAMETERS, ExternalLink };
