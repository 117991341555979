import { jsx, jsxs } from 'react/jsx-runtime.js';
import { useState, useCallback, useEffect } from 'react';
import cn from 'classnames';
import { handlePositiveKeyDown } from '../../utils/keyboard.js';
import styles from './Toggle.module.css.js';

const EMPTY_OBJ = {};
function Toggle(props) {
  const {
    className,
    defaultChecked = false,
    disabled = false,
    htmlProps = EMPTY_OBJ,
    label,
    onChange
  } = props;
  const [switchedOn, setSwitchedOn] = useState(defaultChecked);
  const switchToggle = useCallback(() => {
    setSwitchedOn(!switchedOn);
    if (onChange) {
      onChange(!switchedOn);
    }
  }, [onChange, switchedOn]);
  const handleKeyDown = useCallback(e => {
    handlePositiveKeyDown(e, switchToggle);
  }, [switchToggle]);
  useEffect(() => {
    setSwitchedOn(defaultChecked);
  }, [defaultChecked]);
  return jsx("span", {
    "aria-checked": switchedOn,
    className: cn(styles.checkbox, {
      [styles.disabled]: disabled
    }, className),
    onClick: disabled ? undefined : switchToggle,
    onKeyDown: disabled ? undefined : handleKeyDown,
    role: 'checkbox',
    tabIndex: disabled ? -1 : 0,
    ...htmlProps,
    children: jsxs("span", {
      className: styles.support,
      tabIndex: -1,
      children: [!!label && label.position === 'l' && jsx("span", {
        className: styles.textLabelLeft,
        children: label.text
      }), jsx("span", {
        className: `${styles.switch} ${switchedOn ? styles.on : ''}`
      }), !!label && label.position === 'r' && jsx("span", {
        className: styles.textLabelRight,
        children: label.text
      })]
    })
  });
}

export { Toggle };
