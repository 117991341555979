import { jsx } from 'react/jsx-runtime.js';
import cn from 'classnames';
import styles from './Layout.module.css.js';
export { default as layoutStyles } from './Layout.module.css.js';

const Layout = props => {
  const {
    children,
    className = ''
  } = props;
  return jsx("div", {
    className: cn(styles.horizontalPadding, className),
    children: children
  });
};

export { Layout };
