import { jsxs, jsx } from 'react/jsx-runtime.js';
import { H2 } from '@jetbrains/ring-ui-built/components/heading/heading.js';
import { Button } from '@jetbrains/ring-ui-built/components/button/button.js';
import Close from '@jetbrains/icons/close.js';
import cn from 'classnames';
import styles from './Header.module.css.js';

const Header = props => {
  const {
    className,
    titleTextClassName,
    closeButtonDisabled = false,
    onClick,
    'data-qd-test': dataQdTest
  } = props;
  return jsxs("header", {
    className: cn(styles.title, className),
    children: [jsx(H2, {
      className: cn(styles.titleText, titleTextClassName),
      "data-qd-test": dataQdTest && `${dataQdTest}__title`,
      children: props.children
    }), !!onClick && jsx(Button, {
      "data-qd-test": dataQdTest && `${dataQdTest}__close-button`,
      iconClassName: styles.closeButtonIcon,
      className: styles.closeButton,
      onClick: onClick,
      icon: Close,
      disabled: closeButtonDisabled
    })]
  });
};

export { Header };
