import { jsx } from 'react/jsx-runtime.js';
import { ExternalLink } from '../ExternalLink/ExternalLink.js';

const QODANA_SUPPORT_EMAIL = 'qodana-support@jetbrains.com';
const SupportLink = _ref => {
  let {
    label,
    email = QODANA_SUPPORT_EMAIL
  } = _ref;
  return jsx(ExternalLink, {
    href: `mailto:${email}`,
    children: label !== null && label !== void 0 ? label : email
  });
};

export { QODANA_SUPPORT_EMAIL, SupportLink };
