const isHttpUrl = function (str) {
  let isHttpsOnly = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  if (!str) {
    return false;
  }
  try {
    const url = new URL(str);
    return url.protocol === 'https:' || !isHttpsOnly && url.protocol === 'http:';
  } catch (err) {
    return false;
  }
};

export { isHttpUrl };
