import { useState, useEffect } from 'react';
import { v4 } from 'uuid';

const useNoscroll = (elem, disableScroll) => {
  const [className] = useState(() => `disabledScroll_${v4()}`);
  useEffect(() => {
    const style = document.createElement('style');
    style.innerHTML = `
			.${className} {
				overflow: hidden;
			}
		`;
    document.head.appendChild(style);
    return () => {
      document.head.removeChild(style);
    };
  }, [className]);
  useEffect(() => {
    if (!elem) {
      return;
    }
    if (disableScroll) {
      elem.classList.add(className);
    }
    return () => elem.classList.remove(className);
  }, [disableScroll, elem, className]);
};

export { useNoscroll };
