import { jsxs, jsx } from 'react/jsx-runtime.js';
import { Button } from '@jetbrains/ring-ui-built/components/button/button.js';
import RingCode from '@jetbrains/ring-ui-built/components/code/code.js';
import EyeIcon from '@jetbrains/icons/eye.js';
import Tooltip from '@jetbrains/ring-ui-built/components/tooltip/tooltip.js';
import cn from 'classnames';
import { useState } from 'react';
import '../../styles/Syntax.css.js';
import { ReactComponent as SvgClosedEye } from '../../media/images/closed-eye.svg.js';
import { CopyButton } from '../CopyButton/CopyButton.js';
import styles from './Code.module.css.js';
import { TestAnchor } from './test.js';

const Code = props => {
  const {
    code,
    language,
    codeWithHiddenElements,
    hiddenPartName = 'code',
    copyButtonTestAttribute,
    inline = false,
    horizontalScroll = false,
    onCopy,
    ...restProps
  } = props;
  const [showCodeWithHiddenElements, setShowCodeWithHiddenElements] = useState(true);
  const handleShowChange = () => setShowCodeWithHiddenElements(prevState => !prevState);
  const displayCode = codeWithHiddenElements !== undefined ? showCodeWithHiddenElements ? codeWithHiddenElements : code : code;
  return jsxs("div", {
    ...restProps,
    className: cn(styles.container, {
      [styles.inlineContainer]: inline
    }),
    children: [!inline && jsxs("div", {
      className: styles.buttonGroups,
      children: [codeWithHiddenElements !== undefined && jsx(Tooltip, {
        title: showCodeWithHiddenElements ? `Show ${hiddenPartName}` : `Hide ${hiddenPartName}`,
        children: jsx(Button, {
          "data-qd-test": TestAnchor.eyeButton,
          icon: showCodeWithHiddenElements ? EyeIcon : SvgClosedEye,
          onClick: handleShowChange
        })
      }), jsx(CopyButton, {
        "data-qd-test": copyButtonTestAttribute,
        textToCopy: code,
        onCopy: onCopy
      })]
    }), jsx(RingCode, {
      className: cn(styles.code, {
        [styles.inlineCode]: inline,
        [styles.noScroll]: !horizontalScroll
      }),
      inline: inline,
      language: language,
      code: displayCode
    })]
  });
};

export { Code };
