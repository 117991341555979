import { jsx, jsxs } from 'react/jsx-runtime.js';
import { H1 } from '@jetbrains/ring-ui-built/components/heading/heading.js';
import cn from 'classnames';
import { Fragment } from 'react';
import typographyStyles from '../Typography/Typography.module.css.js';
import styles from './CenteredContentLayout.module.css.js';

const CenteredContentLayout = _ref => {
  let {
    content,
    topContent,
    title,
    isChildrenCentered = false,
    contentClassName,
    'data-qd-test': dataQdTest
  } = _ref;
  const ContentWrapper = isChildrenCentered ? Fragment : 'div';
  return jsx("div", {
    "data-qd-test": dataQdTest,
    className: cn(styles.container, {
      [styles.centered]: isChildrenCentered
    }),
    children: jsxs(ContentWrapper, {
      children: [!!topContent && jsx("div", {
        children: topContent
      }), !!title && jsx(H1, {
        className: styles.title,
        children: title
      }), jsx("div", {
        className: cn(typographyStyles.text, contentClassName),
        children: content
      })]
    })
  });
};

export { CenteredContentLayout };
