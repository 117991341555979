import { FeatureToggles } from '@qodana/report/lib/components/App/context'

import { LicensePlan } from '../types/organization'

export type ConfigFile = {
	dataApiBaseUrl: string
	isStagingContext?: boolean
	queryErrorAlertType?: QueryErrorAlertType
	suppressedQueryErrors?: string[]
	isCreationOrganizationAvailable?: boolean
	isDeletionOrganizationAvailable?: boolean
	isOfflineLicense?: boolean
	isFeedbackFormAvailable?: boolean
	isFusraEnabled?: boolean
	joinTeamButtonEnabled?: boolean
	defaultLicensePlan?: LicensePlan
	featureToggles?: FeatureToggles
	contributorsStateOnProjectCard?: boolean
	supportEmail?: string
	isNewsletterSubscriptionHidden?: boolean
	isProjectSetupEnabled?: boolean
	manageGithubPermissionsUrl?: string
	isInsightsFeatureAvailable?: boolean
}

export type QueryErrorAlertType = 'ui' | 'console'

let loaderPromise: Promise<ConfigFile> | undefined

async function fetchConfig(): Promise<ConfigFile> {
	if (!window.qdConfigLoader) {
		throw new Error('qdConfigLoader is undefined')
	}

	try {
		return (await window.qdConfigLoader).json()
	} catch {
		throw new Error('An error occurred in the loading config process!')
	}
}

export function loadConfig(config?: ConfigFile) {
	if (loaderPromise) {
		return loaderPromise
	}

	if (config) {
		loaderPromise = Promise.resolve(config)
		return loaderPromise
	}

	loaderPromise = fetchConfig()
	return loaderPromise
}

async function getConfigProp<TProp extends keyof ConfigFile>(
	propName: TProp,
): Promise<ConfigFile[TProp]> {
	const config = await getLoaderPromiseIfExists()

	return config[propName]
}

export const getDataApiBaseUrl = () => getConfigProp('dataApiBaseUrl')

export async function getLoaderPromiseIfExists(): Promise<ConfigFile> {
	if (!loaderPromise) {
		throw new Error('The application config is not loaded yet')
	}

	return loaderPromise
}
