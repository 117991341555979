import { useState, useCallback } from 'react';
import { useIsMountedRef } from './useIsMountedRef.js';

const useOpenCloseState = function () {
  let defaultState = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  const [isOpen, setIsOpen] = useState(defaultState);
  const isMounted = useIsMountedRef();
  const open = useCallback(() => {
    if (isMounted.current) {
      setIsOpen(true);
    }
  }, [isMounted]);
  const close = useCallback(() => {
    if (isMounted.current) {
      setIsOpen(false);
    }
  }, [isMounted]);
  return [isOpen, open, close];
};

export { useOpenCloseState };
