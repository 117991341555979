var TestAnchors;
(function (TestAnchors) {
  TestAnchors["stepLabel"] = "progress-bar-step-label";
  TestAnchors["stepNumber"] = "progress-bar-step-number";
  TestAnchors["verticalContent"] = "progress-bar-vertical-content";
  TestAnchors["horizontalContent"] = "progress-bar-horizontal-content";
  TestAnchors["backButton"] = "progress-bar-back-button";
  TestAnchors["nextButton"] = "progress-bar-next-button";
  TestAnchors["completeButton"] = "progress-bar-complete-button";
  TestAnchors["skipButton"] = "progress-bar-skip-button";
  TestAnchors["skipStepButton"] = "progress-bar-skip-step-button";
})(TestAnchors || (TestAnchors = {}));

export { TestAnchors };
