import 'core-js/modules/esnext.iterator.map.js';
import { jsxs, Fragment, jsx } from 'react/jsx-runtime.js';
import cn from 'classnames';
import CheckmarkIcon from '@jetbrains/icons/checkmark.js';
import Icon, { Size } from '@jetbrains/ring-ui-built/components/icon/icon.js';
import { StepPosition } from '../constants.js';
import { StepTitle } from '../StepTitle/StepTitle.js';
import { getStepPosition } from '../helpers/getStepPosition.js';
import { TestAnchors } from '../test.js';
import styles from './HorizontalContent.module.css.js';

const HorizontalContent = props => {
  const {
    steps,
    currentIndex,
    children,
    showTitles
  } = props;
  return jsxs("div", {
    className: styles.container,
    children: [showTitles && jsxs(Fragment, {
      children: [jsx("div", {
        children: steps.map((_ref, index) => {
          let {
            label,
            key
          } = _ref;
          const position = getStepPosition(currentIndex, index);
          return jsxs("div", {
            className: styles.titleContainer,
            children: [jsx(StepTitle, {
              isCurrentStep: position === StepPosition.current,
              children: label
            }), jsx(Icon, {
              glyph: CheckmarkIcon,
              size: Size.Size14,
              className: cn(styles.checkmarkIcon, {
                [styles.visible]: position === StepPosition.previous
              }),
              suppressSizeWarning: true
            })]
          }, key);
        })
      }), jsx("div", {
        className: styles.divider
      })]
    }), jsx("div", {
      className: styles.content,
      "data-qd-test": TestAnchors.horizontalContent,
      children: children
    })]
  });
};

export { HorizontalContent };
