const millisecond = 1;
const second = 1000;
const minute = 60000;
const hour = minute * 60;
const day = hour * 24;
const week = day * 7;
const locale = 'en-GB';
const units = [{
  label: 'm ago',
  value: minute
}, {
  label: 'h ago',
  value: hour
}, {
  value: day
}];
const relatedDate = date => {
  const today = new Date();
  const inputDate = new Date(date);
  const diff = Math.abs(today.valueOf() - inputDate.valueOf());
  for (let i = 0; i < units.length - 1; i++) {
    if (diff < units[i + 1].value) {
      const result = Math.max(Math.floor(diff / units[i].value), 1);
      return `${result}${units[i].label}`;
    }
  }
  const currentYear = today.getFullYear();
  const dateYear = inputDate.getFullYear();
  const showYear = currentYear !== dateYear;
  return formatDate(date, showYear);
};
const toDate = date => {
  return new Date(date);
};
const fullDateToString = date => toDate(date).toLocaleString();
const formatDate = function (date) {
  let showYear = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  let showTime = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  const internalDate = typeof date === 'string' ? new Date(date) : date;
  const dateString = internalDate.toLocaleDateString(locale, {
    day: 'numeric',
    month: 'short',
    year: showYear ? 'numeric' : undefined
  });
  if (!showTime) {
    return dateString;
  } else {
    const timeString = internalDate.toLocaleTimeString(locale, {
      hour: '2-digit',
      minute: '2-digit'
    });
    return `${dateString} ${timeString}`;
  }
};
const getDaysDiff = (date1, date2) => {
  const diff = date1.valueOf() - date2.valueOf();
  return diff / day;
};
const formatDateOrdinalDay = date => {
  let nth;
  const day = date.getDate();
  if (day > 3 && day < 21) {
    nth = 'th';
  } else {
    switch (day % 10) {
      case 1:
        nth = 'st';
        break;
      case 2:
        nth = 'nd';
        break;
      case 3:
        nth = 'rd';
        break;
      default:
        nth = 'th';
        break;
    }
  }
  return `${day}${nth} ${date.toLocaleDateString(locale, {
    month: 'long'
  })}`;
};

export { day, formatDate, formatDateOrdinalDay, fullDateToString, getDaysDiff, hour, locale, millisecond, minute, relatedDate, second, week };
