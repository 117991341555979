import { jsx } from 'react/jsx-runtime.js';
import { useRef, useEffect, useMemo } from 'react';
import { ThemeColor } from '../../../types.js';
import { isDark, updateBodyClass, updateMetaThemeColor } from '../../../utils/theme.js';
import { Theme, ThemeContext } from '../ThemeContext.js';
import styles from './ThemeProvider.module.css.js';

const ThemeProvider = props => {
  const {
    children,
    systemTheme = Theme.light,
    onThemeChange,
    theme = Theme.auto
  } = props;
  const displayedTheme = theme === Theme.auto ? systemTheme : theme;
  const color = isDark(displayedTheme) ? ThemeColor.dark : ThemeColor.light;
  const metaRef = useRef(null);
  useEffect(() => {
    updateBodyClass(displayedTheme);
    updateMetaThemeColor(theme, color, metaRef);
  }, [displayedTheme, color, theme]);
  const value = useMemo(() => ({
    theme,
    onThemeChange
  }), [onThemeChange, theme]);
  return jsx(ThemeContext.Provider, {
    value: value,
    children: jsx("div", {
      className: styles.themeContainer,
      children: children
    })
  });
};

export { ThemeProvider };
