import _defineProperty from '@babel/runtime/helpers/defineProperty';
import { ApplicationMenu } from './applicationMenu.js';

class BaseMenuItem {
  constructor() {
    _defineProperty(this, "id", void 0);
    _defineProperty(this, "click", void 0);
    _defineProperty(this, "enabled", void 0);
    _defineProperty(this, "visible", void 0);
    _defineProperty(this, "htmlProps", void 0);
    _defineProperty(this, "icon", void 0);
    _defineProperty(this, "description", void 0);
    _defineProperty(this, "before", void 0);
    _defineProperty(this, "after", void 0);
  }
}
class SubmenuMenuItem extends BaseMenuItem {
  constructor(_ref) {
    let {
      submenu,
      label,
      ...restOptions
    } = _ref;
    super();
    _defineProperty(this, "type", 'submenu');
    _defineProperty(this, "submenu", void 0);
    _defineProperty(this, "label", void 0);
    this.label = label;
    if (Array.isArray(submenu)) {
      this.submenu = ApplicationMenu.buildFromTemplate(submenu);
    } else {
      this.submenu = new ApplicationMenu();
    }
    Object.assign(this, restOptions);
  }
}
const isSubmenuMenuItem = item => {
  return item.type === 'submenu';
};

export { SubmenuMenuItem, isSubmenuMenuItem };
