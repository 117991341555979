import { jsx, jsxs } from 'react/jsx-runtime.js';
import { useState, useEffect } from 'react';
import { Theme } from '../../Theme/ThemeContext.js';
import { handlePositiveKeyDown } from '../../../utils/keyboard.js';
import { ReactComponent as SvgCrescent } from '../../../media/images/crescent.svg.js';
import { ReactComponent as SvgSun } from '../../../media/images/sun.svg.js';
import styles from './ThemeToggle.module.css.js';

const ThemeToggle = props => {
  const {
    defaultChecked = false,
    htmlProps = {},
    onChange
  } = props;
  const [switchedOn, setSwitchedOn] = useState(defaultChecked);
  const switchToggle = () => {
    setSwitchedOn(!switchedOn);
    if (onChange) {
      onChange(!switchedOn ? Theme.dark : Theme.light);
    }
  };
  const handleKeyDown = e => {
    handlePositiveKeyDown(e, switchToggle);
    if (e.key === 'a') {
      alert('Theme will be synced with system');
      onChange && onChange(Theme.auto);
    }
  };
  useEffect(() => {
    setSwitchedOn(defaultChecked);
  }, [defaultChecked]);
  return jsx("span", {
    className: styles.checkbox,
    onClick: switchToggle,
    onKeyDown: handleKeyDown,
    role: "switch",
    "aria-checked": switchedOn,
    "aria-label": 'toggle dark theme',
    tabIndex: 0,
    ...htmlProps,
    children: jsxs("span", {
      className: styles.helper,
      tabIndex: -1,
      children: [jsx("span", {
        className: `${styles.switch} ${switchedOn ? styles.on : ''}`
      }), jsxs("div", {
        className: styles.iconContainer,
        children: [jsx(SvgSun, {
          className: styles.sunIcon
        }), jsx(SvgCrescent, {
          className: styles.crescentIcon
        })]
      })]
    })
  });
};

export { ThemeToggle };
