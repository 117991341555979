import { equalFlatObjects } from './object.js';

function equalObjArrays(a, b) {
  if (a === b) {
    return true;
  }
  if (a.length === 0 && b.length === 0) {
    return true;
  }
  if (a.length !== b.length) {
    return false;
  }
  for (let i = 0; i < a.length; ++i) {
    if (!equalFlatObjects(a[i], b[i])) {
      return false;
    }
  }
  return true;
}
const equalPrimitiveArrays = (arr1, arr2) => {
  if (arr1 === arr2) {
    return true;
  }
  if (arr1.length !== arr2.length) {
    return false;
  }
  for (let i = 0; i < arr1.length; ++i) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }
  return true;
};
const sortObjArrayBy = (array, sortCriteria) => {
  array.sort((a, b) => {
    let i = 0;
    let result = 0;
    while (i < sortCriteria.length && result === 0) {
      const {
        criterion,
        direction
      } = sortCriteria[i];
      result = direction;
      let valueA = '';
      let valueB = '';
      if (isString(criterion)) {
        valueA = parseFloat(String(a[criterion])) || String(a[criterion]);
        valueB = parseFloat(String(b[criterion])) || String(b[criterion]);
      } else {
        valueA = criterion(a);
        valueB = criterion(b);
      }
      if (valueA < valueB) {
        result *= -1;
      } else if (valueA > valueB) {
        result *= 1;
      } else {
        result = 0;
      }
      i++;
    }
    return result;
  });
};
const countValues = (objArray, key) => {
  const result = {};
  for (const item of objArray) {
    const value = item[key];
    result[value] = result[value] ? result[value] + 1 : 1;
  }
  return result;
};
function isString(criteria) {
  return typeof criteria === 'string';
}

export { countValues, equalObjArrays, equalPrimitiveArrays, sortObjArrayBy };
