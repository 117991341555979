import 'core-js/modules/es.array.push.js';

const splitStringBySubstring = (line, search) => {
  const lowerInput = line.toLowerCase();
  const lowerSubstring = search.toLowerCase();
  const result = [];
  let startIndex = 0;
  if (search === '' || line === '') {
    return [line];
  }
  while (startIndex < lowerInput.length) {
    const indexOfSubstring = lowerInput.indexOf(lowerSubstring, startIndex);
    if (indexOfSubstring === -1) {
      result.push(line.substring(startIndex));
      break;
    }
    if (indexOfSubstring !== 0) {
      result.push(line.substring(startIndex, indexOfSubstring));
    }
    if (indexOfSubstring === 0 && search.length < line.length) {
      result.push('');
    }
    result.push(line.substring(indexOfSubstring, indexOfSubstring + search.length));
    startIndex = indexOfSubstring + search.length;
  }
  return result;
};

export { splitStringBySubstring };
