const unsecuredCopyToClipboard = value => {
  const element = document.createElement('textarea');
  element.value = value;
  element.setAttribute('readonly', '');
  element.style.position = 'absolute';
  element.style.left = '-9999px';
  document.body.appendChild(element);
  element.select();
  // noinspection JSDeprecatedSymbols
  document.execCommand('copy');
  document.body.removeChild(element);
};
const copyToClipboard = value => {
  if (window.isSecureContext && navigator.clipboard) {
    return navigator.clipboard.writeText(value);
  } else {
    return new Promise(() => {
      // for TeamCity in iframe
      unsecuredCopyToClipboard(value);
    });
  }
};

export { copyToClipboard };
