var StepPosition;
(function (StepPosition) {
  StepPosition["previous"] = "previousStep";
  StepPosition["current"] = "currentStep";
  StepPosition["future"] = "futureStep";
})(StepPosition || (StepPosition = {}));
const DEFAULT_SIZE = {
  iconSize: 7,
  lineSize: 70,
  circleSize: 14
};
var Orientation;
(function (Orientation) {
  Orientation["vertical"] = "vertical";
  Orientation["horizontal"] = "horizontal";
})(Orientation || (Orientation = {}));

export { DEFAULT_SIZE, Orientation, StepPosition };
