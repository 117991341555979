import { jsx, jsxs } from 'react/jsx-runtime.js';
import Popup from '@jetbrains/ring-ui-built/components/popup/popup.js';
import cn from 'classnames';
import { useState, useRef, useContext, useEffect, useCallback } from 'react';
import FocusLock from 'react-focus-lock';
import { MODAL_ANIMATION_DURATION } from '../../config/globalConstants.js';
import { useIsMountedRef } from '../../hooks/useIsMountedRef.js';
import { useNoscroll } from '../../hooks/useNoscroll.js';
import '@jetbrains/ring-ui-built/components/global/theme.js';
import '../Theme/ThemeContext.js';
import '../../services/alert/alert.js';
import { FocusRestoreContext } from '../../services/focusRestore/FocusRestoreContext.js';
import '../../services/telemetry/telemetry.js';
import { Header } from './Header/Header.js';
import styles from './ModalWrapper.module.css.js';

const DEFAULT_WIDTH = 670;
const DEFAULT_HEIGHT = 500;
const stopPropagationAndPreventDefaultHandler = e => {
  e.stopPropagation();
  e.preventDefault();
};
const ModalWrapper = props => {
  const {
    className = '',
    keyboard = true,
    maskClosable = true,
    showCloseButton = true,
    onClose,
    closeButtonDisabled = false,
    opened,
    title = null,
    titleClassName,
    titleTextClassName,
    popupContentClassName,
    focusLockClassName,
    width = DEFAULT_WIDTH,
    height = DEFAULT_HEIGHT,
    maxHeight,
    wrapInBlockTag = true
  } = props;
  const [showContent, setShowContent] = useState(true);
  const timeoutRef = useRef();
  const isMountedRef = useIsMountedRef();
  const isCloseButtonShown = showCloseButton && Boolean(onClose);
  const previousFocusedElement = useContext(FocusRestoreContext);
  useEffect(() => () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = undefined;
    }
  }, [opened]);
  const close = useCallback(() => {
    if (!onClose || timeoutRef.current) {
      return;
    }
    setShowContent(false);
    timeoutRef.current = setTimeout(() => {
      onClose();
      if (isMountedRef.current) {
        setShowContent(true);
      }
    }, MODAL_ANIMATION_DURATION);
  }, [isMountedRef, onClose]);
  const onOutsideClick = useCallback(e => {
    const id = e.target.getAttribute('id');
    if (id === `qd_modal_${styles.container}`) {
      close();
    }
  }, [close]);
  const handleFocusLockDeactivation = useCallback(() => {
    if (typeof previousFocusedElement === 'string') {
      document.getElementById(previousFocusedElement)?.focus?.();
    } else {
      previousFocusedElement?.focus?.();
    }
  }, [previousFocusedElement]);
  useNoscroll(document.body, opened);
  // it is important for teamcity extension
  useNoscroll(document.body.parentElement || undefined, opened);
  if (!opened) {
    return null;
  }
  const Fade = wrapInBlockTag ? 'div' : 'span';
  return jsx(Fade, {
    id: `qd_modal_${styles.container}`,
    className: cn(styles.container, {
      [styles.opened]: showContent,
      [styles.closed]: !showContent
    }),
    onClick: stopPropagationAndPreventDefaultHandler,
    "aria-hidden": true,
    children: jsx(Fade, {
      style: {
        width
      },
      children: jsx(Popup, {
        className: cn(styles.popup, {
          [styles.opened]: showContent,
          [styles.closed]: !showContent
        }, className),
        style: {
          width,
          height,
          maxHeight
        },
        "data-test": props['data-qd-e2e-selector'],
        onEscPress: keyboard ? close : undefined,
        onOutsideClick: maskClosable ? onOutsideClick : undefined,
        autoCorrectTopOverflow: false,
        autoPositioning: false,
        children: jsxs(FocusLock, {
          className: cn(styles.focusLock, focusLockClassName),
          returnFocus: true,
          onDeactivation: handleFocusLockDeactivation,
          children: [!!title && jsx(Header, {
            "data-qd-test": props['data-qd-e2e-selector'],
            className: titleClassName,
            titleTextClassName: titleTextClassName,
            onClick: isCloseButtonShown ? close : undefined,
            closeButtonDisabled: closeButtonDisabled,
            children: title
          }), jsx("div", {
            className: cn(styles.popupContent, popupContentClassName),
            children: props.children
          })]
        })
      })
    })
  });
};

export { ModalWrapper };
