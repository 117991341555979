import { jsx } from 'react/jsx-runtime.js';
import { useState, useCallback, useEffect } from 'react';
import 'uuid';
import { useMatchMedia } from '../../../hooks/useMatchMedia.js';
import { Theme } from '../ThemeContext.js';
import { ThemeProvider } from '../ThemeProvider/ThemeProvider.js';

const MEDIA_QUERY_DARK = '(prefers-color-scheme: dark)';
const ThemeSyncWithSystemProvider = props => {
  const {
    children,
    saveTheme,
    getTheme,
    initialTheme
  } = props;
  const isDarkBySystemPreference = useMatchMedia(MEDIA_QUERY_DARK);
  const systemTheme = isDarkBySystemPreference ? Theme.dark : Theme.light;
  const [savedTheme, setSavedTheme] = useState(() => initialTheme || getTheme() || Theme.auto);
  const onThemeChange = useCallback(newTheme => {
    saveTheme(newTheme);
    setSavedTheme(newTheme);
  }, [saveTheme]);
  useEffect(() => {
    onThemeChange(savedTheme);
  }, [onThemeChange, savedTheme]);
  return jsx(ThemeProvider, {
    onThemeChange: onThemeChange,
    theme: savedTheme,
    systemTheme: systemTheme,
    children: children
  });
};

export { ThemeSyncWithSystemProvider };
